import { formRegex } from "../_Common/const";

export default class ReqFormResumeValidator {
    constructor(formBlockClass) {
        console.log(
            `ReqFormResumeValidator class is loaded`
        );
        this.formBlock = document.querySelector(formBlockClass);
        this.teaser = document.querySelector('.popup-resume__teaser');
        // Form elements
        this.form = this.formBlock.querySelector(`.resume-form`);
        this.formMain = this.formBlock.querySelector(`.form__main`);
        this.formSuccess = this.formBlock.querySelector(`.form__success`);
        this.formMistakesContainer = this.formBlock.querySelector(
            `.form__mistakes`
        );
        this.vacation = this.formBlock.querySelector(`.jsTitleInput`);
        this.nameInput = this.formBlock.querySelector(`.jsNameInput`);
        this.surnameInput = this.formBlock.querySelector(`.jsSurnameInput`);
        this.phoneInput = this.formBlock.querySelector(`.jsPhoneInput`);
        this.mailInput = this.formBlock.querySelector(`.jsMailInput`);
        this.linkInput = this.formBlock.querySelector(`.jsLinkInput`);
        this.textInput = this.formBlock.querySelector(`.jsTextInput`);
        this.agreeCheck = this.formBlock.querySelector(`.jsAgreeCheck`);
        this.emptyFields = this.formBlock.querySelectorAll(`.jsIsEmpty`);
        this.submitBtnNode = this.formBlock.querySelector(`.form__submit`);
        this.responseStatusMessageNode = this.formBlock.querySelector(
            `.jsResponseStatus`
        );
        this.reqFields = [this.nameInput, this.surnameInput, this.phoneInput, this.mailInput];

        // Event listeners
        this.reqFields.forEach(inp => {
            inp.addEventListener('blur', (e) => {
                this.validateEmptyInput(e, inp);
            })
        })

        if (this.nameInput) {
            this.nameInput.addEventListener(`input`, (e) => {
                this.handleNameInput(e);
            });
        }
        if (this.surnameInput) {
            this.surnameInput.addEventListener(`input`, (e) => {
                this.handleSurnameInput(e);
            });
        }
        if (this.phoneInput) {
            this.phoneInput.addEventListener(`input`, (e) => {
                this.handlePhoneInput(e);
            });
        }
        if (this.mailInput) {
            this.mailInput.addEventListener(`input`, (e) => {
                this.handleMailInput(e);
            });
        }
        if (this.agreeCheck) {
            this.agreeCheck.addEventListener(`change`, (e) => {
                this.handleAgreeCheck(e);
            });
        }

        if (this.form) {
            this.form.addEventListener(`submit`, (e) => {
                e.preventDefault();
                this.handleSubmit(e);
            });
        }

        // Kinda sorta state 😜
        this.nameIsValid = false;
        this.surnameIsValid = false;
        this.phoneIsValid = false;
        this.mailIsValid = false;
        this.agreeIsValid = false;
    }

    validateEmptyInput = (e, el) => {
        const val = e.target.value;
        if (!val.length) this.showUserMistake(el);
    };

    // 🤩 🤩 🤩
    handleNameInput = (e) => {
        this.validateNameInput(e);
    };

    validateNameInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.nameInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.nameInput.classList.add(`jsIsEmpty`);
            this.nameIsValid = false;
        } else if (val.length >= 1) {
            this.nameInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.nameInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.nameIsValid = true;
        } else {
            this.nameInput.classList.remove(`success`, `jsSuccess`);
            this.nameInput.classList.add(`error`, `jsError`);
            this.nameIsValid = false;
        }
    };

    // 🤩 🤩 🤩 🤩
    handleSurnameInput = (e) => {
        this.validateSurnameInput(e);
    };

    validateSurnameInput = (e) => {
        const val = e.target.value;
        if (!val.length) {
            this.surnameInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.surnameInput.classList.add(`jsIsEmpty`);
            this.surnameIsValid = false;
        } else if (val.length >= 1) {
            this.surnameInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.surnameInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.surnameIsValid = true;
        } else {
            this.surnameInput.classList.remove(`success`, `jsSuccess`);
            this.surnameInput.classList.add(`error`, `jsError`);
            this.surnameIsValid = false;
        }
    };

    // 📱 📱 📱
    handlePhoneInput = (e) => {
        this.validatePhoneInput(e);
    };

    validatePhoneInput = (e) => {
        const val = e.target.value;
        let formatLength = this.formatNumber(val);
        if (!val.length) {
            this.phoneInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.phoneInput.classList.add(`jsIsEmpty`);
            this.phoneIsValid = false;
        } else if (formatLength == 16) {
            this.phoneInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.phoneInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.phoneIsValid = true;
        } else {
            this.phoneInput.classList.remove(`success`, `jsSuccess`);
            this.phoneInput.classList.add(`error`, `jsError`);
            this.phoneIsValid = false;
        }
    };

    formatNumber = (val) => {
        let num = val.replace(/^(8|7|\+7)/ , '' ).replace( /\D/g, '' ).split( /(?=.)/ ), i = num.length;
        if ( 0 <= i ) num.unshift( '+7' );
        if ( 1 <= i ) num.splice( 1, 1, '(9' );
        if ( 4 <= i ) num.splice( 4, 0, ')' );
        if ( 7 <= i ) num.splice( 8, 0, '-' );
        if ( 9 <= i ) num.splice( 11, 0, '-' );
        if ( 11 <= i ) num.splice( 15, num.length - 15 );
        this.phoneInput.value  = num.join( '' );
        let valLength = this.phoneInput.value.length; // длина в новом формате
        let v = this.phoneInput.value.replace(/[^+\d]/g, '');
        this.phoneInput.setAttribute('data-value', v);
        return valLength;
    }

    // 💌 💌 💌
    handleMailInput = (e) => {
        this.validateMailInput(e);
    };

    validateMailInput = (e) => {
        this.handleMailErrorUpdate(e);
        const val = e.target.value;
        if (!val.length) {
            this.mailInput.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.mailInput.classList.add(`jsIsEmpty`);
            this.mailIsValid = false;
        } else if (val.length > 0 && val.match(formRegex.mailValidationRegex)) {
            this.mailInput.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.mailInput.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.mailIsValid = true;
        } else {
            this.mailInput.classList.remove(`success`, `jsSuccess`);
            this.mailInput.classList.add(`error`, `jsError`);
            this.mailIsValid = false;
        }
    };

    //✅ ✅ ✅
    handleAgreeCheck = (e) => {
        this.validateAgreeCheck(e);
    };

    validateAgreeCheck = (e) => {
        this.handleMailErrorUpdate(e);

        if (!this.agreeCheck.checked) {
            this.agreeCheck.classList.remove(
                `error`,
                `jsError`,
                `success`,
                `jsSuccess`
            );
            this.agreeCheck.classList.add(`jsIsEmpty`);
            this.agreeIsValid = false;
        } else if (this.agreeCheck.checked) {
            this.agreeCheck.classList.remove(`jsIsEmpty`, `error`, `jsError`);
            this.agreeCheck.classList.add(`success`, `jsSuccess`);
            this.hideUserMistake(e);
            this.agreeIsValid = true;
        }
    };

    handleMailErrorUpdate = (e) => {
        const parent = e.target.closest(`.form__item`);
        const errorDiv = parent.querySelector(`.form__item-mistake`);
        if (errorDiv) {
            errorDiv.innerText = this.handleEmailErrorMessage();
        }
    };

    // Validate form
    validateForm = () => {
        if (
            this.nameIsValid &&
            this.surnameIsValid &&
            this.phoneIsValid &&
            this.mailIsValid &&
            this.agreeIsValid
        ) {
            return true;
        } else {
            this.hideAllMistakes();
            this.showUserMistakes();
            return false;
        }
    };

    showUserMistakes = () => {
        let invalidFields = this.formBlock.querySelectorAll(`.jsError, .jsIsEmpty`);
        invalidFields.forEach((el) => {
            this.showUserMistake(el);
        });
    };

    showUserMistake = el => {
        let errorDiv = document.createElement("div");
        errorDiv.classList.add(
            `error`,
            `form__item-mistake`
        );
        let errorString = ``;
        if (el.classList.contains(`jsNameInput`)) {
            errorString = `Заполните это поле`;
        } else if (el.classList.contains(`jsSurnameInput`)) {
            errorString = `Заполните это поле`;
        } else if (el.classList.contains(`jsPhoneInput`)) {
            errorString = `Заполните это поле`;
        } else if (el.classList.contains(`jsMailInput`)) {
            errorString = this.handleEmailErrorMessage();
        } else if (el.classList.contains(`jsAgreeCheck`)) {
            errorString = `Подтвердите согласие`;
        }
        errorDiv.innerHTML = errorString;
        if(!el.parentElement.querySelector(`.form__item-mistake`)) {
            el.classList.add('is-invalid');
            el.parentElement.appendChild(errorDiv);
        }
    }

    handleEmailErrorMessage = () => {
        const mailInput = this.mailInput.value;
        if (mailInput.length === 0) return `Заполните это поле`;
        const marker = mailInput.indexOf(`@`);
        if (marker === -1) return `E-mail должен содержать символ «@»`;
        const substrings = mailInput.split(`@`);
        if (substrings[0].length === 0)
            return `Введите часть e-mail до символа «@»`;
        if (substrings[1].length === 0)
            return `Введите часть e-mail после символа «@»`;
        return `Продолжайте ввод e-mail`;
    };

    hideUserMistake = (e) => {
        e.target.classList.remove('is-invalid');
        const parent = e.target.closest(`.form__item`);
        const errorDiv = parent.querySelector(`.form__item-mistake`);
        if (errorDiv) errorDiv.remove();
    };

    hideAllMistakes = () => {
        const errorElements = this.formBlock.querySelectorAll(`.jsError, .jsIsEmpty`);
        errorElements.forEach((el) => {
            const parent = el.closest(`.form__item`);
            const errorDiv = parent.querySelector(`.form__item-mistake`);
            el.classList.remove('is-invalid');
            if (errorDiv) errorDiv.remove();
        });
    };

    handleSubmit = (e) => {
        if (this.validateForm()) {
            console.log(`Networking`);
            this.sendViaFetch();
        } else {
            e.preventDefault();
        }
    };

    sendViaFetch = async () => {
        console.log(`Sending via fetch`);
        $(this.formMistakesContainer).hide()
        const url = "/send-request/";
        const data = {
            vacation: this.vacation.value,
            name: this.nameInput.value,
            surname: this.surnameInput.value,
            phone: this.phoneInput.getAttribute('data-value'),
            email: this.mailInput.value,
            text: this.textInput.value,
            link: this.linkInput.value,
            confirm: this.agreeCheck.value,
        };
        Object.entries(data).map(a => Object.entries(a[1]).filter(b => b[1].length).length ? a:delete data[a[0]]);

        const dataString = JSON.stringify(data);
        let response = await fetch(url, {
            method: "POST",
            // mode: "cors",
            // withCredentials: true,
            // credentials: "include",
            // credentials: "same-origin",
            headers: {
                "x-requested-with": "XMLHttpRequest",
                "Content-Type": "application/json"
            },
            body: dataString,
        });

        if (response.ok) {
            console.log(`Response OK`);
            try {
                let responseJson = await response.json();
                this.handleResponse(responseJson);
            } catch (err) {
                console.error("Error parsing json from " + url + "\n" + err);
                this.showSubmitFail();
            }
        } else {
            console.error("http error while getting url: " + response.status);
            this.showSubmitFail();
        }
    };

    handleResponse = (responseJson) => {
        console.log(`Handling response`);
        if (responseJson.message == 'Success')
        {
            this.showSubmitSuccess();
        }
        else
        {
            this.showSubmitFail();
        }
    };

    showSubmitSuccess = () => {
        console.log(`Show submit success`);
        $(this.teaser).hide();
        $(this.formMain).hide();
        $(this.formSuccess).show();

        if (!$(this.formBlock).hasClass('popup-resume')) {
            $('html,body').animate({
                scrollTop: $(this.formSuccess).offset().top - ( $(window).height() - $(this.formSuccess).outerHeight(true) ) / 2
            }, 200);
        }
    };

    showSubmitFail = () => {
        console.log(`Showing submit fail`);
        this.formMistakesContainer.innerHTML = '<p>Произошла ошибка</p>'
        $(this.formMistakesContainer).show()
    };
}

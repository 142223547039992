$(document).ready(function() {
    var cookieName = "cookies-notification-hide";
    var cookieValue = encodeURIComponent("1");
    var cookieFullValue = cookieValue + "; path=/; max-age=604800";
    if (document.cookie.indexOf(cookieName) === -1) {
        $(".popup-cookie").show();
    }

    $(".popup-cookie__close").click(function(e) {
        document.cookie = encodeURIComponent(cookieName) + "=" + cookieFullValue;
        $(".popup-cookie").hide();
    });
});

window.addEventListener('load', () => {
	if (animItems.length > 0) {
		animOnScroll();
		window.addEventListener('scroll', animOnScroll);
	}
});

const animItems = document.querySelectorAll('.anim-item');

function animOnScroll(params) {
	for (let index = 0; index < animItems.length; index++) {
        const animItem = animItems[index];
        const animItemRect = animItem.getBoundingClientRect();
		const animItemHeight = animItem.offsetHeight;
		const animItemOffsetTop = offset(animItem).top;
		const animItemOffsetBottom = animItemOffsetTop + animItemHeight;
		const animStart = 3;

		let animItemPointY = window.innerHeight - animItemHeight / animStart;

		if (animItemHeight > window.innerHeight) {
			animItemPointY = window.innerHeight - window.innerHeight / animStart;
		}

		if(pageYOffset > animItemOffsetTop - animItemPointY && pageYOffset < animItemOffsetTop + animItemHeight) {
			animItem.classList.add('active');
		} else {
			if (!animItem.classList.contains('anim-item--no-hide')) {
				animItem.classList.remove('active');
			}
		}
	}
};

function offset(el) {
	const rect = el.getBoundingClientRect(),
	scrollTop = window.pageYOffset || document.documentElement.scrollTop,
	scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	return {
		top: rect.top + scrollTop,
		left: rect.left + scrollLeft
	}
};

$('a[href^="#"]').click(function () {
	let elementClick = $(this).attr("href").replace(/.*#/, '');
	if (elementClick && $('a[name='+elementClick+']').length)
	{
		let destination = $('a[name='+elementClick+']').offset().top;
		console.log(destination);
		$('html, body').animate( { scrollTop: destination }, 1100 );
	}
});

// Popup
$(".popup__close, .popup__btn-close").click(function(e){
	$("body").removeClass("no-scroll");
	$(this).closest(".popup").removeClass("active");
});

$(".popup").click(function(e) {
	var callbackPopup = $(".popup__container")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$(this).removeClass("active");
		$("body").removeClass("no-scroll");
	};
});

$(".vacancies-card__more").click(function (e){
    $("body").addClass("no-scroll");

    if($(this).hasClass("vacancies-card__more--to-common")) {
        $(".popup-vacancy--common").addClass("active");
    } else {
        $(".popup-vacancy--" + $(this).closest(".vacancies__catalog-item").index().toString()).addClass("active");
    }
});

$(".popup-vacancy__resume-btn").click(function (e){
	$(".popup-vacancy").removeClass("active");
    $(".popup-resume").addClass("active");

    if (!$(this).hasClass("popup-vacancy__resume-btn--common")) {
        $(".popup-resume__position-title").text("на должность «" + $(this).parent(".popup-vacancy__resume").siblings(".popup-vacancy__title").text() + "» ");
        $(".resume-form__position-title").val($(this).parent(".popup-vacancy__resume").siblings(".popup-vacancy__title").text());
    } else {
        $(".popup-resume__position-title").text("");
        $(".resume-form__position-title").val("Без вакансии");
    }
});

import "../Footer/Footer";
import ReqFormResumeValidator from "../form/FormResume";
window.ReqFormResumeValidator = ReqFormResumeValidator;


// import ReqFormValidator from "../form/form";
// window.ReqFormValidator = ReqFormValidator;
// new ReqFormValidator('.request');
// new ReqFormValidator('.popup--request');

// // Form format tel
// $.fn.formatPnoneNumber = function(){
// 	return this.each(function(){
// 		$(this).bind('keyup', function(){
// 			var num = this.value.replace(/^(8|7|\+7)/ , '' ).replace( /\D/g, '' ).split( /(?=.)/ ), i = num.length;
// 			if ( 0 <= i ) num.unshift( '+7' );
// 			if ( 1 <= i ) num.splice( 1, 0, '(' );
// 			if ( 4 <= i ) num.splice( 5, 0, ')' );
// 			if ( 7 <= i ) num.splice( 9, 0, '-' );
// 			if ( 9 <= i ) num.splice( 12, 0, '-' );
// 			if ( 11 <= i ) num.splice( 15, num.length - 15 );
// 			this.value = num.join( '' );
// 		});
// 	});
// };

// $('.request__form .jsPhoneInput').formatPnoneNumber()

